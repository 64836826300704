.containerCard {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.card-buttons {
    position: absolute;
    bottom: 0px;
    width: 100%;
}

.card-preview {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}