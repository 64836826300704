html, body, .viewport {
    width: 100%;
    min-height: 100% !important;
    height: 100%;
    margin: 0;
    overflow-y: hidden;
  }

* {
    font-family: 'Kufam', cursive;
    font-weight: 500;
}

*::selection {
    color:#2B6535;
    background: #0AC9B0;
}

.home {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.navbar {
    background-color: #2B6535;
    margin-top  : 0px;
    padding: 20px 20px;

    top: 0;
    right: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 10%;
}

.navbar .logo {
    align-items: center;
    justify-content: center;
    display: flex;
}

.navbar .title {
    width: fit-content;
    background-color: #0AC9B0;

    padding: 4px 8px;
    border: 2px solid black;
    border-radius: 8px;

    font-family: 'Fira Code';
    font-size: 24px;
}

.navbar .github {
    align-items: center;
    justify-content: center;
    display: flex;
}

.project {
    display: flex;
    height: 90%;
}

.project .left-side {
    width: 70%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.project .left-side .top-side {
    background-color: #9FD39C;
    border: 2px solid black;
    margin: 16px;
    padding: 16px;
    border-radius: 20px;
    height: 10%;
}

.project .left-side .bottom-side {
    background-color: #9FD39C;
    border: 2px solid black;
    margin: 16px;
    padding: 16px;
    border-radius: 20px;
    height: 80%;
}

.project .right-side {
    background-color: #9FD39C;
    border: 2px solid black;
    margin: 16px;
    padding: 16px;
    border-radius: 20px;

    width: 30%;
    display: flex;
}