.button {
    cursor: pointer;
    border-radius: 24px;
    padding: 8px 12px;
    text-align: center;
    border: 2px solid black;
    width: fit-content;
    height: fit-content;
    transition: 0.2s;
    color:white;
    margin: 4px;
    box-sizing: border-box;
}

.button.mini {
    font-size: small;
    padding: 4px 8px;
}

.button.max-width {
    width: 100%;
}

.button.config {
    background-color: gray;
}

.button.config:hover {
    background-color: white;
    color: gray;
    border: 2px solid gray;
    transition: 0.2s;
}

.button.confirm {
    background-color: #2B912D;
}

.button.confirm:hover {
    background-color: white;
    color: #2B912D;
    border: 2px solid #2B912D;
    transition: 0.2s;
}

.button.new {
    background-color: #2B918A;
}

.button.new:hover {
    background-color: white;
    color: #2B918A;
    border: 2px solid #2B918A;
    transition: 0.2s;
}

.button.reject {
    background-color: red;
}

.button.reject:hover {
    background-color: white;
    color: red;
    border: 2px solid red;
    transition: 0.2s;
}